<template>
    <div class="contractor-form-page">
        <el-form
            ref="form"
            class="geology-form"
            :rules="formRules"
            :model="form"
            label-width="120px"
            style="margin-top: 30px"
        >
            <el-row>
                <el-col :span="11" :offset="1">
                    <el-form-item label="运维单位名称" prop="Name">
                        <el-autocomplete
                            popper-class="autocomplete-contractor-name"
                            v-model="form.Name"
                            :fetch-suggestions="querySearch"
                            placeholder="运维单位名称"
                            size="small"
                            @select="handleSelect"
                        >
                            <i
                                class="el-icon-edit el-input__icon"
                                slot="suffix"
                            >
                            </i>
                            <template slot-scope="{ item }">
                                <div class="name">{{ item.Name }}</div>
                                <span class="addr">{{ item.Contacts }}</span>
                            </template>
                        </el-autocomplete>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="1">
                    <el-form-item label="公司电话" prop="Tel">
                        <el-input v-model="form.Tel" size="small"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="11" :offset="1">
                    <el-form-item label="单位联系人" prop="Contacts">
                        <el-input
                            v-model="form.Contacts"
                            size="small"
                        ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="1">
                    <el-form-item label="电话" prop="Mobile">
                        <el-input v-model="form.Mobile" size="small"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="22" :offset="1">
                    <el-form-item label="单位地址" prop="Address">
                        <el-input
                            v-model="form.Address"
                            size="small"
                        ></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>

        <div
            class="dialog-footer"
            style="text-align: right; padding: 10px 0 20px 0"
        >
            <el-button
                type="success"
                icon="el-icon-edit"
                size="small"
                @click="onSubmit"
                >保 存</el-button
            >
            <el-button
                size="small"
                style="margin-left: 10px"
                plain
                type="primary"
                @click="onCancel"
                >关 闭</el-button
            >
        </div>
    </div>
</template>
<style lang="scss">
.autocomplete-contractor-name {
    li {
        line-height: normal;
        padding: 7px;

        .name {
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .addr {
            font-size: 12px;
            color: #b4b4b4;
        }

        .highlighted .addr {
            color: #ddd;
        }
    }
}
</style>
<script>
import { addMaintain, editMaintain,defaultMaintainUnit } from "@/api/base";
export default {
    data() {
        return {
            defaultMaintainUnitData:[],
            form: {
                Name: "",
                Address: "",
                Tel: "",
                Contacts: "",
                Mobile: "",
                Id: 0,
            },
            formRules: {
                Name: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入运维单位名称",
                    },
                ],
            },
        };
    },
    props: {
        formtype: {
            type: String,
            default: "add",
        },
        detail: {
            type: Object,
        },
    },
    methods: {
        onSubmit() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if (this.formtype != "edit") {
                        addMaintain(this.form)
                            .then((res) => {
                                if (res.code == 0) {
                                    this.$message.success("保存成功!");
                                    this.$emit("cancel");
                                } else {
                                    this.$message.error("保存失败!" + res.msg);
                                }
                            })
                            .catch((errmsg) => {
                                this.$message.error("保存失败!" + errmsg);
                            });
                    } else {
                        editMaintain(this.form)
                            .then((res) => {
                                if (res.code == 0) {
                                    this.$message.success("保存成功!");
                                    this.$emit("cancel");
                                } else {
                                    this.$message.error("保存失败!" + res.msg);
                                }
                            })
                            .catch((errmsg) => {
                                this.$message.error("保存失败!" + errmsg);
                            });
                    }
                } else {
                    return false;
                }
            });
        },
        onCancel() {
            this.$emit("cancel");
        },
        querySearch(queryString, cb) {
            cb(this.defaultMaintainUnitData);
        },
        handleSelect(item) {
            this.form = JSON.parse(JSON.stringify(item))
        },
        onLoadDefaultMaintainUnit() {
            defaultMaintainUnit().then((res) => {
                if (res.code == 0) {
                    this.defaultMaintainUnitData = res.data;
                }
            });
        },
    },
    created() {
        this.onLoadDefaultMaintainUnit();
        if (this.formtype == "edit") {
            this.form = JSON.parse(JSON.stringify(this.detail));
        } else {
            this.form = {
                Name: "",
                Address: "",
                Tel: "",
                Contacts: "",
                Mobile: "",
                Id: 0,
            };
        }
    },
    watch: {
        detail: function () {
            this.form = JSON.parse(JSON.stringify(this.detail));
        },
    },
};
</script>